import { HttpService } from '../http/http.service';
import { IBkgGeosucheResult } from './bkg-geosuche-result.interface';

class BkgGeosucheService extends HttpService {
  constructor() {
    super(
      'https://sg.geodatenzentrum.de/gdz_ortssuche__b97484ee-35c5-f59c-4040-f0062cfa13d6/',
    );
  }

  fetchGeosuche(query: string): Promise<IBkgGeosucheResult> {
    return this.get(
      `geosearch?count=10&properties=text&query=${encodeURIComponent(query)}`,
    );
  }
}

export const bkgGeosucheService = new BkgGeosucheService();
