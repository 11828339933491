import Vue from 'vue';

Vue.directive('click-outside', {
  bind: (el: any, binding: any, vnode: any) => {
    el.event = (event: any) => {
      if (!(el === event.target || el.contains(event.target))) {
        const node: any = vnode.context[binding.expression];
        if (!node) {
          return;
        }
        node(event);
      }
    };
    document.body.addEventListener('click', el.event);
  },
  unbind: (el: any) => {
    document.body.removeEventListener('click', el.event);
  },
});
