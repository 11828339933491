import { NotificationVariants } from './notification-variants.enum';

interface Notification {
  nachricht: string;
  title: string;
  variant: NotificationVariants;
}

class NotificationService {
  public defaultTimeout = 5000;
  public notifications: Notification[] = [];

  public createNotification(nachricht: string, title: string, variant = NotificationVariants.DEFAULT): Notification {
    return {
      nachricht,
      title,
      variant,
    };
  }

  public sendNotification(notification: Notification) {
    this.notifications.push(notification);
    setTimeout(() => {
      const index = this.notifications.findIndex(notificationTmp => notificationTmp === notification);
      this.notifications.splice(index, 1);
    }, this.defaultTimeout);
  }

  public sendErrorNotification(nachricht: string, title = 'Fehler') {
    const notification = this.createNotification(nachricht, title, NotificationVariants.DANGER);
    this.sendNotification(notification);
  }
}

export const notificationService = new NotificationService();
