export class HttpService {
  url: string;

  constructor(baseUrl: string) {
    this.url = baseUrl;
  }

  public async get<T>(schnittstelle: string): Promise<T> {
    const finalUrl = this.url + schnittstelle;
    const response = await fetch(finalUrl);
    if (!response.ok) {
      throw new Error(
        `Fehler bei der Post Anfrage an API (${finalUrl}) (${response.statusText})`,
      );
    }
    return response.json();
  }

  public async post<T>(
    schnittstelle: string,
    body: { [key: string]: any },
  ): Promise<T> {
    const finalUrl = this.url + schnittstelle;
    const response = await fetch(finalUrl, {
      headers: {
        // tslint:disable-next-line: object-literal-key-quotes
        Accept: 'application/json; text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(
        `Fehler bei der Post Anfrage an API (${finalUrl}) (${response.statusText})`,
      );
    }
    return response.json();
  }

  public async put<T>(
    schnittstelle: string,
    body: { [key: string]: any },
  ): Promise<T> {
    const finalUrl = this.url + schnittstelle;
    const response = await fetch(finalUrl, {
      headers: {
        // tslint:disable-next-line: object-literal-key-quotes
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(
        `Fehler bei der Post Anfrage an API (${finalUrl}) (${response.statusText})`,
      );
    }
    return response.json();
  }

  public async delete<T>(schnittstelle: string): Promise<T> {
    const finalUrl = this.url + schnittstelle;
    const response = await fetch(finalUrl, {
      headers: {
        // tslint:disable-next-line: object-literal-key-quotes
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    });
    if (!response.ok) {
      throw new Error(
        `Fehler bei der Delete Anfrage an API (${finalUrl}) (${response.statusText})`,
      );
    }
    return await response.json();
  }
}
