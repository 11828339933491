import Vue from 'vue';
import VueRouter from 'vue-router';

import PlanerView from '@/views/planer/Planer.view.vue';

Vue.use(VueRouter);

export const ROUTES = {
  HOME: '/',
  PLANER: '/planer'
};

export const router = new VueRouter({
  routes: [
    {
      path: ROUTES.HOME,
      redirect: ROUTES.PLANER,
    },
    {
      path: ROUTES.PLANER,
      component: PlanerView
    }
  ],
});
