import { render, staticRenderFns } from "./PunktTextInput.component.vue?vue&type=template&id=7393921c&scoped=true&"
import script from "./PunktTextInput.component.vue?vue&type=script&lang=ts&"
export * from "./PunktTextInput.component.vue?vue&type=script&lang=ts&"
import style0 from "./PunktTextInput.component.vue?vue&type=style&index=0&id=7393921c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7393921c",
  null
  
)

export default component.exports