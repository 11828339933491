














































































import Vue from 'vue';
import Component from 'vue-class-component';
import { notificationService } from '@/services/notification/notification.service';
import { ROUTES } from './router';

@Component
export default class App extends Vue {
  public notificationService = notificationService;
  public ROUTES = ROUTES;

  get notifications() {
    return notificationService.notifications;
  }
}
