

































































import { IBkgRoutingResult } from '@/services/bkg/bkg-routing-result.interface';
import { Component, Prop, Vue } from 'vue-property-decorator';
import GpsTracksComponent from './GpsTracks.component.vue';
import HoehenprofilComponent from './Hoehenprofil.component.vue';

@Component({
  components: {
    GpsTracksComponent,
    HoehenprofilComponent,
  }
})
export default class ZusatzinfoComponent extends Vue {
  @Prop({ type: Object, required: true })
  routingResult!: IBkgRoutingResult;

  tabs = {
    HOEHENPROFIL: {
      id: 'HOEHENPROFIL',
    },
    GPSTRACKS: {
      id: 'GPSTRACKS',
    }
  }
  selectedTab = null;

  selectTab(tab: any) {
    if (this.selectedTab === tab) {
      this.selectedTab = null;
      return;
    }
    this.selectedTab = tab;
  }
}
