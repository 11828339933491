











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { bkgGeosucheService } from '@/services/bkg/bkg-geosuche.service';
import { Wegpunkt } from '@/classes/wegpunkt.class';
import { notificationService } from '@/services/notification/notification.service';

@Component
export default class PunktTextInputComponent extends Vue {
  @Prop()
  label!: string;

  @Prop({
    type: Object,
    required: true
  })
  punkt!: Wegpunkt;

  ignoreNextSearchChange = false;
  awaitingSearch = false;
  searchResults: any[] = [];
  isFocused = false;
  isLoading = false;
  hoverIndex: number | null = null;
  @Prop({
    type: Boolean,
    required: true,
  })
  displayRemove!: boolean;

  @Watch('punkt.text')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  punktTextChanged(newVal: string, oldVal: string) {
    if (this.ignoreNextSearchChange) {
      this.ignoreNextSearchChange = false;
      return;
    }

    if (newVal.length < 2) {
      return;
    }

    if (!this.awaitingSearch) {
      setTimeout(async () => {
        this.awaitingSearch = false;
        this.isLoading = true;
        this.hoverIndex = null;
        try {
          this.searchResults = (await bkgGeosucheService.fetchGeosuche(this.punkt.text)).features
        } catch (e) {
          console.error(e);
          notificationService.sendErrorNotification('Fehler beim Abrufen der Adressen')
          this.searchResults = [];
        }
        this.isLoading = false;
      }, 1000)
    }
    this.awaitingSearch = true;
  }

  selectSearchResult(searchResult: any) {
    this.ignoreNextSearchChange = true;
    this.punkt.text = searchResult.properties.text;
    this.punkt.coordinates = [searchResult.geometry.coordinates[1], searchResult.geometry.coordinates[0]];
    this.searchResults = [];
    this.$emit('input')
  }

  onKeydownDown() {
    if (this.searchResults.length === 0) {
      return;
    }
    if (this.hoverIndex === null || this.hoverIndex === this.searchResults.length - 1) {
      this.hoverIndex = 0;
      return;
    }
    this.hoverIndex = this.hoverIndex + 1;
  }

  onKeydownUp() {
    if (this.searchResults.length === 0) {
      return;
    }
    if (this.hoverIndex === null || this.hoverIndex === 0) {
      this.hoverIndex = this.searchResults.length - 1;
      return;
    }
    this.hoverIndex = this.hoverIndex - 1;
  }

  onKeydownEnter() {
    if (this.hoverIndex === null) {
      return;
    }
    this.selectSearchResult(this.searchResults[this.hoverIndex]);
  }
}
