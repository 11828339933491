













import { IBkgRoutingResult } from '@/services/bkg/bkg-routing-result.interface';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class GpsTracksComponent extends Vue {
  @Prop({ type: Object, required: true })
  routingResult!: IBkgRoutingResult;

  download() {
    console.log(this.routingResult)
    const isIE = this.isInternetExplorer(),
      downloadString = JSON.stringify(this.routingResult),
      fileName = "Gesamtroute.geojson";

    if (isIE) {
      window.navigator.msSaveOrOpenBlob(new Blob([downloadString]), fileName);
    }
    else {
      const url = `data:text/plain;charset=utf-8,${encodeURIComponent(downloadString)}`,
        a = document.createElement("a");

      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  isInternetExplorer() {
    if ((/MSIE 9/i).test(navigator.userAgent)) {
      // ie = "IE9";
      return true;
    }
    else if ((/MSIE 10/i).test(navigator.userAgent)) {
      // ie = "IE10";
      return true;
    }
    else if ((/rv:11.0/i).test(navigator.userAgent)) {
      // ie = "IE11";
      return true;
    }
    return false;
  }
}
