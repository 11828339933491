import { HttpService } from '../http/http.service';
import { IBkgRoutingResult } from './bkg-routing-result.interface';

class BkgRoutingService extends HttpService {
  constructor() {
    super('https://sg.geodatenzentrum.de/web_ors__b97484ee-35c5-f59c-4040-f0062cfa13d6/v2/')
  }

  fetchRadroute(coordinates: [number, number][]): Promise<IBkgRoutingResult> {
    return this.post('directions/cycling-regular/geojson', {
      coordinates: coordinates,
      geometry: true,
      instructions: false,
      language: 'de',
      preference: 'recommended',
      units: 'm'
    });
  }
}

export const bkgRoutingService = new BkgRoutingService();
