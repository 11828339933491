







































































































import { Component, Vue } from 'vue-property-decorator';
import PunktTextInputComponent from '@/components/input/PunktTextInput.component.vue';
import { LatLngBounds, Map } from 'leaflet';
import { bkgRoutingService } from '@/services/bkg/bkg-routing.service';
import { IBkgRoutingResult, IBkgRoutingResultFeature } from '@/services/bkg/bkg-routing-result.interface';
import { notificationService } from '@/services/notification/notification.service';
import { Wegpunkt } from '@/classes/wegpunkt.class';
import ZusatzinfoComponent from '@/components/zusatzinfos/Zusatzinfo.component.vue';

@Component({
  components: {
    PunktTextInputComponent,
    ZusatzinfoComponent
  }
})
export default class PlanerView extends Vue {
  attribution = `&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors`;
  wegpunkte = [
    new Wegpunkt(),
    new Wegpunkt(),
  ];
  map!: Map;
  routingResult: IBkgRoutingResult | null = null;
  isLoading = false;
  displayControl = true;

  get arrowComponent() {
    return this.displayControl
      ? 'BIconChevronDoubleLeft'
      : 'BIconChevronDoubleRight'
  }

  abbrechen() {
    this.wegpunkte = [
      new Wegpunkt(),
      new Wegpunkt(),
    ];
    this.routingResult = null;
  }

  isZwischenpunkt(index: number) {
    return !(index === 0 || index === this.wegpunkte.length - 1);
  }

  addWegpunkt() {
    this.wegpunkte.splice(this.wegpunkte.length - 1, 0, new Wegpunkt());
  }

  removeWegpunkt(index: number) {
    this.wegpunkte.splice(index, 1);
  }

  getLabel(index: number) {
    if (index === 0) {
      return 'Startpunkt'
    } else if (index === this.wegpunkte.length - 1) {
      return 'Zielpunkt'
    } else {
      return `Zwischenpunkt ${index}`
    }
  }

  onMapReady() {
    this.map = (this.$refs.map as any).mapObject;
  }

  getLatLngBounds() {
    const bounds = new LatLngBounds([]);
    this.wegpunkte.forEach(wegpunkt => bounds.extend(wegpunkt.coordinates as any))
    return bounds;
  }

  getRoutingLatLngBounds() {
    if (!this.routingResult) {
      return new LatLngBounds([]);
    }
    return new LatLngBounds([
      [this.routingResult.bbox[1], this.routingResult.bbox[0]],
      [this.routingResult.bbox[3], this.routingResult.bbox[2]],
    ]).pad(0.05);
  }

  async startRouting() {
    if (this.wegpunkte.some(wegpunkt => !wegpunkt.isValidCoordinates())) {
      return;
    }
    this.routingResult = null;
    this.isLoading = true;
    try {
      this.routingResult = await bkgRoutingService.fetchRadroute(
        this.wegpunkte.map(wegpunkt => ([wegpunkt.coordinates[1], wegpunkt.coordinates[0]]))
      );
      this.map.fitBounds(this.getRoutingLatLngBounds())
    } catch (error) {
      console.error(error)
      notificationService.sendErrorNotification('Fehler beim abrufen der Route');
    }
    this.isLoading = false;
  }

  getCoordinatesFromFeature(feature: IBkgRoutingResultFeature) {
    return feature.geometry.coordinates.map(coords => ([coords[1], coords[0]]))
  }
}
