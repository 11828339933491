import Vue from 'vue';
import App from './App.vue';
import { router } from './router';

// ##############################################################
// Bootstrap
// ##############################################################

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// ##############################################################
// Vue2Leaflet
// ##############################################################

import { LMap, LTileLayer, LMarker, LControlZoom, LControl, LPolyline } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('LMap', LMap);
Vue.component('LTileLayer', LTileLayer);
Vue.component('LMarker', LMarker);
Vue.component('LControl', LControl);
Vue.component('LControlZoom', LControlZoom);
Vue.component('LPolyline', LPolyline);

import { Icon } from 'leaflet';

type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// ##########################################
// Components

import Loading from '@/components/common/Loading.vue';
Vue.component('Loading', Loading);

// ########################################
// Directives
// ########################################
import './directives/click-outside.directive';

// ##########################################

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
